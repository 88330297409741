var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "informationDetails commonF" },
    [
      _c("infoTable", {
        attrs: { tableData: _vm.tableData, num: 1, nameStyle: _vm.nameStyle },
      }),
      _c(
        "div",
        { staticClass: "content wrapperS" },
        [
          _c("dir", { staticClass: "title" }, [_vm._v("消息内容：")]),
          _c("div", {
            staticClass: "txt",
            domProps: { innerHTML: _vm._s(this.viewData.msgContent) },
          }),
        ],
        1
      ),
      _vm.$route.query.path !== "notice"
        ? _c("div", { staticClass: "title" }, [_vm._v("工单详情")])
        : _vm._e(),
      _vm.$route.query.path !== "notice"
        ? _c("infoTable", {
            staticClass: "info",
            attrs: {
              tableData: _vm.orderDetailsData,
              dataValue: _vm.detail,
              num: 2,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "30px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go("-1")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
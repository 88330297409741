<!--
 * @Author: your name
 * @Date: 2020-08-03 09:48:15
 * @LastEditTime: 2020-10-26 16:46:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\allInformation\allinformationDetails.vue
-->
<template>
  <div class="informationDetails commonF">
    <infoTable :tableData="tableData" :num="1" :nameStyle="nameStyle" />
    <div class="content wrapperS">
      <dir class="title">消息内容：</dir>
      <div v-html="this.viewData.msgContent" class="txt"></div>
    </div>
    <div class="title" v-if="$route.query.path !== 'notice'">工单详情</div>
    <infoTable
      v-if="$route.query.path !== 'notice'"
      class="info"
      :tableData="orderDetailsData"
      :dataValue="detail"
      :num="2"
    />
    <div style="text-align: center; margin-top: 30px;">
      <el-button type="primary" @click="$router.go('-1')">返回</el-button>
    </div>
  </div>
</template>
<script>
import infoTable from "@/components/infoTable/infoTable";
export default {
  components: {
    infoTable,
  },
  data() {
    return {
      tableData: [],
      viewData: [],
      orderDetailsData: [
        {
          key: "orderCode",
          name: "事件编号",
        },
        {
          key: "area",
          name: "区域",
        },
        {
          key: "parentParkIdName",
          name: "所属父车场",
        },
        {
          key: "parkName",
          name: "所属子车场",
        },
        {
          key: "berthCoeds",
          name: "泊位号",
        },
        {
          key: "equipType",
          name: "工单设备类型",
        },
        {
          key: "faultTypeName",
          name: "上报故障类型",
        },
        {
          key: "state",
          name: "当前状态",
        },
        {
          key: "reportTime",
          name: "创建时间",
        },
        {
          key: "createdTime",
          name: "派工时间",
        },
        {
          key: "shelveTime",
          name: "搁置时间",
        },
        {
          key: "finishTime",
          name: "完工时间",
        },
        {
          key: "finishFaultDesc",
          name: "实际故障类型",
        },
        {
          key: "shelveDesc",
          name: "搁置原因",
        },
      ],
      nameStyle: {
        width: "199px",
      },
      detail: {},
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/acb/2.0/sysmgMsgConf/getById", {
          data: {
            msgConfId: this.$route.query.msgConfId,
          },
        })
        .then((res) => {
          this.viewData = res.value;
          this.setTableData();
        });
    },
    getDetails() {
      let url = "/acb/2.0/aomEquOrder/byCode/" + this.$route.query.expand;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.detail = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    setTableData() {
      this.tableData = [
        {
          value: this.viewData.msgTitle,
          name: "消息标题：",
        },
        {
          value:
            // this.$route.query.msgType === 0 ? "管理员通知" : "设备异常报警",
            "通知消息", // 本期只有通知消息有详情，写死
          name: "消息类型：",
        },
        {
          value: this.viewData.sendTime,
          name: "发送时间：",
        },
      ];
    },
  },
  mounted() {
    this.getData();
    if (this.$route.query.path !== "notice") {
      this.getDetails();
    }
  },
};
</script>
<style lang="stylus" scoped>
.info >>> .wrapperS .colValue > div{
  width 95%
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
}
.wrapperS{
  border: 1px solid #ebeef5;
  border-top none;
}
.content {
  overflow hidden;
  width 100%
  .title {
    width: 199px;
    margin 0;
    padding 0;
    float: left;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #f5f7fa;
  }
  .txt {
    box-sizing border-box!important;
    width 65%;
    line-height 30px;
    text-align center;
    float left;
    border-top none;
  }
}
.title{
  margin 20px 0
}
</style>
